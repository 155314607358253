
import fileDownload from 'js-file-download'
import { mapState } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    componentLoader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      success: false,
      giftDonationForm: {},
      giftSendTypeSelected: false,
      preSelectedTab: 0,
      donationCertificateError: false,
      originalCustomMessage: null,
    }
  },
  computed: {
    ...mapState('page', ['page']),
    donationType() {
      if (this.item.donation_type && this.item.donation_type.value) {
        return this.item.donation_type.value
      }

      return 'general'
    },
    customDonationOptions() {
      if (this.item.custom_donation_options) {
        return this.item.custom_donation_options
          .map((value) => parseInt(value))
          .filter((value) => !isNaN(value))
      }

      return []
    },
    donationOptions() {
      if (
        this.item.use_custom_donation_options &&
        this.customDonationOptions.length
      ) {
        return this.customDonationOptions
      }

      if (this.donationType === 'patronage') {
        return [
          {
            if: 'paymentType() == onetime',
            then: [80, 100, 150, 200],
          },
          {
            if: 'paymentType() == recurring && recurringInterval() == monthly',
            then: [10, 20, 50, 100],
          },
          {
            if: 'paymentType() == recurring && recurringInterval() == quarterly',
            then: [20, 40, 80, 100],
          },
          {
            if: 'paymentType() == recurring && recurringInterval() == semestral',
            then: [40, 80, 100, 125],
          },
          {
            if: 'paymentType() == recurring && recurringInterval() == yearly',
            then: [80, 100, 150, 200],
          },
        ]
      }

      if (this.$route.query.donationOptions)
        return this.$route.query.donationOptions
      return [30, 60, 90, 120]
    },
    paymentPurposesTitle() {
      if (this.donationType === 'gift') return 'Angaben zur begünstigten Person'
      if (this.donationType === 'sorrow') return 'In Gedenken an'
      return 'Spendenzweck wählen'
    },
    showWidget() {
      return (
        this.donationType !== 'gift' ||
        this.giftSendTypeSelected ||
        this.$route.query['rnw-view']
      )
    },

    personalDataTranslations() {
      if (this.donationType === 'company') {
        return {
          stored_customer_salutation: 'Anrede Kontaktperson',
          stored_customer_firstname: 'Vorname Kontaktperson',
          stored_customer_lastname: 'Nachname Kontaktperson',
          stored_customer_email: 'Email Kontaktperson',
        }
      }

      return {
        stored_customer_salutation: 'Anrede',
        stored_customer_firstname: 'Vorname',
        stored_customer_lastname: 'Nachname',
        stored_customer_email: 'Email',
      }
    },

    defaultWidged() {
      return ['general', 'company', 'online'].includes(this.donationType)
    },

    showRecipientAddress() {
      return '(paymentForm(stored_recipient_send_per_post)) == true && (paymentForm(stored_send_gift_to_recipient)) == true'
    },
  },
  watch: {
    showWidget() {
      if (this.showWidget) {
        this.importWidget()
      }
    },
  },
  mounted() {
    if (this.showWidget) {
      this.importWidget()
    }
  },
  methods: {
    importWidget() {
      // the raisenow form has to be imported at runtime
      // when importing it in the nuxt config file it can lead to issues when visiting
      // different forms on different pages withtout full page reload
      const scriptExists = document.getElementById('raisenow-script')
      if (scriptExists) {
        document.head.removeChild(scriptExists)
      }
      const raisenowScript = document.createElement('script')
      raisenowScript.setAttribute(
        'src',
        'https://tamaro.raisenow.com/denk-an-mich/latest/widget.js'
      )
      raisenowScript.setAttribute('id', 'raisenow-script')

      raisenowScript.addEventListener('load', () => {
        this.loadWidget()
      })
      document.head.appendChild(raisenowScript)
    },

    setCssVars() {
      // css vars has to be set after the widget is loaded
      // otherwise the widget will overwirte the vars again
      const root = document.documentElement

      const vars = [
        ['--tamaro-primary-color', '#0097a2'],
        ['--tamaro-primary-color__hover', '#0097a2'],
        ['--tamaro-primary-bg-color', '#e0fcff'],
        ['--tamaro-error-color', '#ff8778'],
        ['--tamaro-button-primary-color', 'black'],
        ['--tamaro-button-primary-color__hover', 'white'],
        ['--tamaro-button-primary-bg-color', '#ff8778'],
        ['--tamaro-button-primary-bg-color__hover', '#0097a2'],
        ['--tamaro-label-color', 'rgb(73, 73, 73)'],
        ['--text-opacity', '1'],
        ['--bg-opacity', '1'],
        ['--border-opacity', '1'],
        ['--placeholder-opacity', '1'],
        ['--transform-translate-x', '1'],
        ['--transform-translate-y', '1'],
        ['--transform-rotate', 'unset'],
        ['--transform-skew-x', 'unset'],
        ['--transform-skew-y', 'unset'],
        ['--transform-scale-x', 'unset'],
        ['--transform-scale-y', 'unset'],
      ]

      vars.forEach((value) => {
        root.style.setProperty(value[0], value[1])
      })
    },

    loadWidget() {
      this.renderWidget()
      window.rnw.tamaro.events.afterCreate.subscribe(
        this.setupCustomFieldValidation
      )

      window.rnw.tamaro.events.afterCreate.subscribe(this.setCssVars)

      // this is used for conversion tracking of completed payments
      window.rnw.tamaro.events.paymentComplete.subscribe((event) => {
        this.$gtm.push({
          event: 'raisenow_payment_complete',
          payment_currency: event.data.api.transactionInfo.currency,
          payment_amount: parseInt(event.data.api.transactionInfo.amount, 10),
          donation_type: this.donationType,
          payment_method: event.data.api.transactionInfo.payment_method,
        })

        this.$fb.fbq('track', 'Donate', {
          value: parseInt(event.data.api.transactionInfo.amount, 10),
          currency: event.data.api.transactionInfo.currency,
        })

        if (event.data.api.transactionInfo.epayment_status === 'success') {
          this.success = true
        } else {
          this.success = false
        }

        const data = event.data.api.paymentForm.data

        if (data.stored_customer_message) {
          const match = data.stored_customer_message.match(/Bemerkung: (.+)/)
          if (match) {
            data.stored_customer_message = match[1]
          }
        }
      })

      // only needed for loging purpose
      // window.rnw.tamaro.events.paymentMethodChanged.subscribe((event) => {
      //   console.log(event.data.api)
      // })

      window.rnw.tamaro.events.beforePaymentSend.subscribe((event) => {
        const formData = event.data.api.paymentForm.data

        if (!this.originalCustomMessage) {
          this.originalCustomMessage = formData.stored_customer_message
        }

        formData.stored_customer_company =
          formData.stored_customer_company || formData.stored_company_name

        if (
          formData.payment_method === 'chqr' &&
          formData.stored_dont_show_contact_person_on_payment_order
        ) {
          formData.payer_line1 = formData.stored_customer_company
        }
        if (this.donationType === 'sorrow') {
          const sorrowcontact =
            'Trauerkontakt: ' +
            (formData.stored_enable_sorrow_contact
              ? `${formData.stored_sorrow_contact_firstname}, ${formData.stored_sorrow_contact_lastname},
                ${formData.stored_customer_street}, ${formData.stored_customer_street_number},
                ${formData.stored_customer_zip_code}, ${formData.stored_customer_city}`
              : 'Nicht angegeben')

          formData.stored_customer_message = `In Gedenken an ${formData.stored_recipient_firstname} ${formData.stored_recipient_lastname}
            \\\\ ${sorrowcontact}
            \\\\ Bemerkung: ${this.originalCustomMessage}`
        } else if (this.donationType === 'patronage') {
          formData.stored_customer_message = `${
            formData.payment_type === 'recurring' ? 'Wiederkehrende ' : ''
          }Gönnerspede von ${formData.stored_recipient_firstname} ${
            formData.stored_recipient_lastname
          }
            \\\\ Bemerkung: ${this.originalCustomMessage}`
        } else if (this.donationType === 'gift') {
          formData.stored_customer_message = `Schenkungsurkunde für
          ${formData.stored_recipient_firstname} ${
            formData.stored_recipient_lastname
          }
            \\\\ Versand: ${
              formData.stored_recipient_send_per_post ? 'Ja' : 'Nein'
            }`

          if (
            formData.stored_recipient_send_per_post &&
            formData.stored_send_gift_to_recipient
          ) {
            formData.stored_customer_message += ` \\\\ Versand an ${formData.stored_recipient_firstname} ${formData.stored_recipient_lastname},
              ${formData.stored_recipient_street} ${formData.stored_recipient_street_number},
              ${formData.stored_recipient_zip_code} ${formData.stored_recipient_city}`
          } else if (formData.stored_recipient_send_per_post) {
            formData.stored_customer_message += ` \\\\ Versand an ${formData.stored_customer_firstname} ${formData.stored_customer_lastname}`
          }

          formData.stored_customer_message += ` \\\\ Bemerkung: ${this.originalCustomMessage}`
        }
      })
    },
    renderWidget() {
      const slots = {
        [this.donationType === 'company'
          ? 'profile_form_start'
          : 'address_form_start']: [
          {
            component: 'field',
            type: 'text',
            name: 'stored_company_name',
            label: 'payment_form.stored_company_name',
          },
        ],
      }

      if (this.donationType !== 'sorrow' && this.donationType !== 'gift') {
        slots.profile_form_4 = [
          {
            component: 'field',
            type: 'textarea',
            name: 'stored_customer_message',
            label: 'payment_form.stored_customer_message',
          },
        ]
      }

      if (this.donationType === 'company') {
        slots.profile_form_4.unshift({
          if: 'paymentMethod() == chqr',
          component: 'field',
          type: 'checkbox',
          name: 'stored_dont_show_contact_person_on_payment_order',
          text: 'payment_form.stored_dont_show_contact_person_on_payment_order',
        })
      }

      if (
        this.donationType === 'online' ||
        this.donationType === 'online_with_custom_donation_type'
      ) {
        slots.profile_form_4.push({
          component: 'field',
          type: 'checkbox',
          name: 'stored_enable_address',
          text: 'payment_form.stored_enable_address',
        })
      }

      if (this.donationType === 'gift' || this.donationType === 'sorrow') {
        slots.purposes_end = [
          {
            component: 'field',
            type: 'text',
            name: 'stored_recipient_firstname',
            label: 'payment_form.stored_recipient_firstname',
          },
          {
            component: 'field',
            type: 'text',
            name: 'stored_recipient_lastname',
            label: 'payment_form.stored_recipient_lastname',
          },
        ]
      }

      if (this.donationType === 'sorrow') {
        slots.purposes_end.push(
          {
            component: 'field',
            type: 'checkbox',
            name: 'stored_enable_sorrow_contact',
            text: 'payment_form.stored_enable_sorrow_contact',
          },
          {
            if: '(paymentForm(stored_enable_sorrow_contact)) == true',
            component: 'field',
            type: 'text',
            name: 'stored_sorrow_contact_firstname',
            label: 'payment_form.stored_recipient_firstname',
          },
          {
            if: '(paymentForm(stored_enable_sorrow_contact)) == true',
            component: 'field',
            type: 'text',
            name: 'stored_sorrow_contact_lastname',
            label: 'payment_form.stored_recipient_lastname',
          },
          {
            if: '(paymentForm(stored_enable_sorrow_contact)) == true',
            component: 'field',
            type: 'text',
            name: 'stored_sorrow_contact_street',
            label: 'payment_form.stored_customer_street',
          },
          {
            if: '(paymentForm(stored_enable_sorrow_contact)) == true',
            component: 'field',
            type: 'text',
            name: 'stored_sorrow_contact_street_number',
            label: 'payment_form.stored_customer_street_number',
          },
          {
            if: '(paymentForm(stored_enable_sorrow_contact)) == true',
            component: 'field',
            type: 'text',
            name: 'stored_sorrow_contact_zip_code',
            label: 'payment_form.stored_customer_zip_code',
          },
          {
            if: '(paymentForm(stored_enable_sorrow_contact)) == true',
            component: 'field',
            type: 'text',
            name: 'stored_sorrow_contact_city',
            label: 'payment_form.stored_customer_city',
          }
        )
      }

      if (this.donationType === 'gift') {
        slots.purposes_end.push(
          {
            if: this.showRecipientAddress,
            component: 'field',
            type: 'text',
            name: 'stored_recipient_street',
            label: 'payment_form.stored_customer_street',
          },
          {
            if: this.showRecipientAddress,
            component: 'field',
            type: 'text',
            name: 'stored_recipient_street_number',
            label: 'payment_form.stored_customer_street_number',
          },
          {
            if: this.showRecipientAddress,
            component: 'field',
            type: 'text',
            name: 'stored_recipient_zip_code',
            label: 'payment_form.stored_customer_zip_code',
          },
          {
            if: this.showRecipientAddress,
            component: 'field',
            type: 'text',
            name: 'stored_recipient_city',
            label: 'payment_form.stored_customer_city',
          }
        )
      }

      if (this.donationType === 'sorrow' || this.donationType === 'gift') {
        slots.purposes_end.push({
          component: 'field',
          type: 'textarea',
          name: 'stored_customer_message',
          label: 'payment_form.stored_customer_message',
        })
      }

      const paymentWidgetBlocks = [
        'payment_amounts_and_intervals',
        'payment_payment_methods',
        'payment_profile',
      ]

      if (
        this.donationType === 'online' ||
        this.donationType === 'online_with_custom_donation_type'
      ) {
        paymentWidgetBlocks.push({
          if: 'paymentMethod() == chqr || paymentForm(stored_enable_address) == true',
          then: 'payment_address',
        })
      } else {
        paymentWidgetBlocks.push('payment_address')
      }
      const debug = process.env.raisenowTestModeEnabled === true
      if (
        this.donationType !== 'patronage' &&
        this.donationType !== 'general_without_purpose' &&
        this.donationType !== 'online_with_custom_donation_type'
      ) {
        paymentWidgetBlocks.unshift('payment_purposes')
      }

      window.rnw.tamaro.runWidget('.rnw-widget-container', {
        debug,
        debugSlots: debug,

        language: 'de',
        amounts: this.donationOptions,
        testMode: debug,
        faqEntries: [],
        slots,
        uiScrollOffset: 200,
        purposes: this.defaultWidged
          ? ['general', 'accessible_reka_vilages']
          : ['general'],
        defaultPurpose: 'general',
        showStoredCustomerMessage: false,
        paymentWidgetBlocks,
        paymentTypes:
          this.donationType === 'patronage'
            ? ['recurring', 'onetime']
            : ['onetime'],
        minimumCustomAmount:
          this.donationType === 'patronage'
            ? [
                {
                  if: 'paymentType() == onetime',
                  then: 80,
                },
                {
                  if: 'paymentType() == recurring && recurringInterval() == monthly',
                  then: 7,
                },
                {
                  if: 'paymentType() == recurring && recurringInterval() == quarterly',
                  then: 20,
                },
                {
                  if: 'paymentType() == recurring && recurringInterval() == semestral',
                  then: 40,
                },
                {
                  if: 'paymentType() == recurring && recurringInterval() == yearly',
                  then: 80,
                },
              ]
            : null,
        paymentMethods:
          this.donationType === 'online_with_custom_donation_type'
            ? ['vis', 'eca', 'amx', 'pfc', 'pef', 'twi']
            : ['vis', 'eca', 'amx', 'pfc', 'pef', 'twi', 'chqr'],
        paymentFormPrefill: {
          stored_customer_message: this.item.stored_customer_message || null,
          stored_recipient_firstname: null,
          stored_recipient_lastname: null,
          stored_recipient_send_per_post: false,
          stored_send_gift_to_recipient: null,
          stored_recipient_street: null,
          stored_recipient_street_number: null,
          stored_recipient_zip_code: null,
          stored_recipient_city: null,
          ...this.giftDonationForm,
          stored_company_name: null,
          stored_donation_purpose:
            this.donationType === 'online_with_custom_donation_type'
              ? this.item.stored_donation_type
              : this.donationType,
          stored_customer_country: 'CH',
          stored_enable_sorrow_contact: false,
          stored_sorrow_contact_firstname: null,
          stored_sorrow_contact_lastname: null,
          stored_sorrow_contact_street: null,
          stored_sorrow_contact_street_number: null,
          stored_sorrow_contact_zip_code: null,
          stored_sorrow_contact_city: null,
          stored_enable_address: false,
          stored_dont_show_contact_person_on_payment_order: false,
          stored_sxt_product_id:
            this.donationType === 'patronage' ? 128013 : undefined,
          stored_sxt_contract_template_id:
            this.donationType === 'patronage' ? 409636874 : undefined,
        },
        purposeDetails: {
          general: {
            stored_campaign_id: 2065445,
            stored_campaign_subid: 5168,
          },
          accessible_reka_vilages: {
            stored_campaign_id: 2999333,
            stored_campaign_subid: 95280,
          },
        },
        translations: {
          de: {
            payment_form: {
              stored_customer_message: 'Bemerkung (optional)',
              ...this.personalDataTranslations,
              stored_recipient_firstname: 'Vorname',
              stored_recipient_lastname: 'Nachname',
              stored_customer_email_permission:
                'Ja, ich möchte zukünftig über die Stiftung Denk an mich und ihre Projekte auf dem Laufenden gehalten werden und melde mich hiermit für den Newsletter an',
              stored_company_name:
                this.donationType === 'company' ? 'Firma' : 'Firma (optional)',
              stored_enable_sorrow_contact: 'Adresse Trauerfamilie (optional)',
              stored_dont_show_contact_person_on_payment_order:
                'Kontaktperson auf dem Einzahlungsschein bitte nicht aufführen',
              stored_enable_address:
                'Ja, ich möchte eine Spendenbestätigung für die Steuererkärung erhalten',
            },
            purposes: {
              general: 'Allgemeine Spende',
              accessible_reka_vilages: 'Barrierefreie Reka-Dörfer',
            },
            blocks: {
              payment_purposes: {
                title: this.paymentPurposesTitle,
              },
              payment_amounts_and_intervals: {
                title: 'Betrag der Spende wählen',
              },
            },
            validation_errors: {
              stored_recipient_firstname: {
                field_is_missing: 'Bitte Vorname angeben',
              },
              stored_recipient_lastname: {
                field_is_missing: 'Bitte Nachname angeben',
              },
              stored_recipient_street: {
                field_is_missing: 'Bitte Strassenname angeben',
              },
              stored_recipient_street_number: {
                field_is_missing: 'Bitte Hausnummer angeben',
              },
              stored_recipient_zip_code: {
                field_is_missing: 'Bitte Postleizahl angeben',
              },
              stored_recipient_city: {
                field_is_missing: 'Bitte Ort angeben',
              },
              amount: {
                minimum_custom_amount:
                  this.donationType === 'patronage'
                    ? 'Der kleinst mögliche Gönnerbetrag beträgt 80 CHF Gesamtsumme pro Kalenderjahr'
                    : 'Der kleinstmögliche Spendenbetrag ist {{min}}',
              },
              stored_company_name: {
                field_is_missing: 'Bitte Firma angeben',
              },
            },
          },
        },
      })
    },
    setupCustomFieldValidation(event) {
      const widget = event.data.api

      const nameRequired =
        this.donationType === 'gift' || this.donationType === 'sorrow'

      const paymentValidations = window.rnw.tamaro.toJS(
        widget.computedConfig.paymentValidations
      )

      widget.config.paymentValidations = {
        ...paymentValidations,
        stored_customer_city: {
          if: `paymentMethod() == chqr || paymentForm(stored_enable_address) == true || ${
            this.donationType === 'online' ||
            this.donationType === 'online_with_custom_donation_type'
              ? 'false'
              : 'true'
          }`,
          required: true,
        },
        stored_customer_country: {
          if: `paymentMethod() == chqr || paymentForm(stored_enable_address) == true || ${
            this.donationType === 'online' ||
            this.donationType === 'online_with_custom_donation_type'
              ? 'false'
              : 'true'
          }`,
          required: true,
        },
        stored_customer_street: {
          if: `paymentMethod() == chqr || paymentForm(stored_enable_address) == true || ${
            this.donationType === 'online' ||
            this.donationType === 'online_with_custom_donation_type'
              ? 'false'
              : 'true'
          }`,
          required: true,
        },
        stored_customer_zip_code: {
          if: `paymentMethod() == chqr || paymentForm(stored_enable_address) == true || ${
            this.donationType === 'online' ||
            this.donationType === 'online_with_custom_donation_type'
              ? 'false'
              : 'true'
          }`,
          required: true,
        },
        stored_recipient_firstname: { required: nameRequired },
        stored_recipient_lastname: { required: nameRequired },
        stored_company_name: { required: this.donationType === 'company' },
        stored_recipient_street: {
          if: this.showRecipientAddress,
          required: this.donationType === 'gift',
        },
        stored_recipient_street_number: {
          if: this.showRecipientAddress,
          required: this.donationType === 'gift',
        },
        stored_recipient_zip_code: {
          if: this.showRecipientAddress,
          required: this.donationType === 'gift',
        },
        stored_recipient_city: {
          if: this.showRecipientAddress,
          required: this.donationType === 'gift',
        },
      }
    },
    async downloadDonationCertificate() {
      await this.$axios
        .$get(`donation-certificate/${this.$route.query.epp_transaction_id}`, {
          responseType: 'blob',
        })
        .then((data) => {
          this.donationCertificateError = false
          fileDownload(data, 'Schenkungsurkunde.pdf')
        })
        .catch(() => {
          this.donationCertificateError = true
        })
    },
    goBackToGiftDonationForm() {
      this.preSelectedTab = 2
      this.giftSendTypeSelected = false
    },
  },
}
